<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { View } from '@element-plus/icons-vue'

export default {
  setup() {
    TimeAgo.addDefaultLocale(en)
    const timeAgo = new TimeAgo('en-US')
    return {
      View,
      timeAgo
    }
  },
  data() {
    return {
      tab: 'list'
    }
  },
  methods: {
    tabChanged(name) {
      if (name === 'statistics') {
        this.showStats()
      } else if (name === 'hierarchy') {
        this.showHierarchy()
      } else if (name === 'one-one') {
        this.showOneOne()
      }
    },
    showStats() {
      this.$axios.get('/admin/api/stats/number-employees/').then((resp) => {
        this.initChartNumberOfEmployees(resp)
        this.initChartSales(resp)
        this.initChartHiresNumber(resp)
      })
      this.$axios.get('/admin/api/stats/numbers-projects/').then((resp) => {
        this.initChartDonat(resp, 'chart4', 'people')
        this.initChartDonat(resp, 'chart5', 'cost')
        this.initChartDonat2(resp, 'chart6', 'people')
        this.initChartDonat2(resp, 'chart7', 'cost')
      })
    },
    showOneOne() {
      this.$axios.get('/admin/api/stats/hierarchy/').then((resp) => {
        this.initChartOneOne(resp)
      })
    },
    showHierarchy() {
      this.$axios.get('/admin/api/hierarchy/').then((resp) => {
        this.initChartHierarchy(resp)
      })
    },
    initChartNumberOfEmployees(resp) {
      const root = am5.Root.new('chart1')
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingTop: 27,
          paddingBottom: 27
        })
      )
      chart.zoomOutButton.set('forceHidden', true)

      chart.children.unshift(
        am5.Label.new(root, {
          text: 'Number of employees',
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          x: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        })
      )

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none'
        })
      )
      cursor.lineY.set('visible', false)

      const xRenderer = am5xy.AxisRendererX.new(root, {})
      xRenderer.labels.template.set('visible', false)
      xRenderer.grid.template.set('forceHidden', true)
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: {
            timeUnit: 'month',
            count: 1
          },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const xRendererTotal = am5xy.AxisRendererX.new(root, { opposite: true })
      xRendererTotal.labels.template.set('visible', false)
      xRendererTotal.grid.template.set('forceHidden', true)
      const xAxisTotal = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'month', count: 1 },
          renderer: xRendererTotal,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )
      xAxisTotal.get('tooltip').label.adapters.add('text', () => {
        let sum = 0
        chart.series.each((series) => {
          const dataItem = series.get('tooltipDataItem')
          if (dataItem && !series.columns.template.get('forceHidden')) {
            sum += dataItem.get('valueY')
          }
        })
        return `Total: [bold]${root.numberFormatter.format(sum)}[/]`
      })

      const yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.grid.template.set('forceHidden', true)
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
          visible: false
        })
      )

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          stacked: true,
          fill: am5.color(0x4970AA),
          xAxis,
          yAxis,
          valueYField: 'b2b',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'B2B: {valueY}',
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const series2 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series2',
          stacked: true,
          fill: am5.color(0x1B965C),
          xAxis,
          yAxis,
          valueYField: 'coe',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'CoE: {valueY}',
            dy: 30,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const series3 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxisTotal,
          yAxis,
          valueYField: 'b2b',
          valueXField: 'date'
        })
      )
      series3.columns.template.setAll({
        forceHidden: true
      })

      const data = resp.data.data
      series.data.setAll(data)
      series2.data.setAll(data)
      series3.data.setAll(data)
      series.appear(1000)
      series2.appear(1000)
      series3.appear(1000)
      chart.appear(1000, 100)
    },
    initChartSales(resp) {
      const root = am5.Root.new('chart2')
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingTop: 27,
          paddingBottom: 27
        })
      )
      chart.zoomOutButton.set('forceHidden', true)

      chart.children.unshift(
        am5.Label.new(root, {
          text: 'Sales',
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          x: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        })
      )

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none'
        })
      )
      cursor.lineY.set('visible', false)

      const xRenderer = am5xy.AxisRendererX.new(root, {})
      xRenderer.labels.template.set('visible', false)
      xRenderer.grid.template.set('forceHidden', true)
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: {
            timeUnit: 'month',
            count: 1
          },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const xRendererTotal = am5xy.AxisRendererX.new(root, { opposite: true })
      xRendererTotal.labels.template.set('visible', false)
      xRendererTotal.grid.template.set('forceHidden', true)
      const xAxisTotal = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'month', count: 1 },
          renderer: xRendererTotal,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )
      xAxisTotal.get('tooltip').label.adapters.add('text', () => {
        let sum = 0
        chart.series.each((series) => {
          const dataItem = series.get('tooltipDataItem')
          if (dataItem && !series.columns.template.get('forceHidden')) {
            sum += dataItem.get('valueY')
          }
        })
        return `Total: [bold]${root.numberFormatter.format(sum)} PLN[/]`
      })

      const yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.grid.template.set('forceHidden', true)
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
          visible: false
        })
      )

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          stacked: true,
          fill: am5.color(0x4970AA),
          xAxis,
          yAxis,
          valueYField: 's_b2b',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'B2B: {valueY} PLN',
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const series2 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series2',
          stacked: true,
          fill: am5.color(0x1B965C),
          xAxis,
          yAxis,
          valueYField: 's_coe',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'CoE: {valueY} PLN',
            dy: 30,
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const series3 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxisTotal,
          yAxis,
          valueYField: 'b2b',
          valueXField: 'date'
        })
      )
      series3.columns.template.setAll({
        forceHidden: true
      })

      const data = resp.data.data
      series.data.setAll(data)
      series2.data.setAll(data)
      series3.data.setAll(data)
      series.appear(1000)
      series2.appear(1000)
      series3.appear(1000)
      chart.appear(1000, 100)
    },
    initChartHiresNumber(resp) {
      const root = am5.Root.new('chart3')
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX'
        })
      )
      chart.zoomOutButton.set('forceHidden', true)

      chart.children.unshift(
        am5.Label.new(root, {
          text: 'Number of hires & leaves',
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          x: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        })
      )

      const cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none'
        })
      )
      cursor.lineY.set('visible', false)

      const xRenderer = am5xy.AxisRendererX.new(root, {})
      xRenderer.labels.template.set('visible', false)
      xRenderer.grid.template.set('forceHidden', true)
      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: {
            timeUnit: 'month',
            count: 1
          },
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )

      const xRendererTotal = am5xy.AxisRendererX.new(root, { opposite: true })
      xRendererTotal.labels.template.set('visible', false)
      xRendererTotal.grid.template.set('forceHidden', true)
      const xAxisTotal = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: 'month', count: 1 },
          renderer: xRendererTotal,
          tooltip: am5.Tooltip.new(root, {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )
      xAxisTotal.get('tooltip').label.adapters.add('text', () => {
        let sum = 0
        chart.series.each((series) => {
          const dataItem = series.get('tooltipDataItem')
          if (dataItem && !series.columns.template.get('forceHidden')) {
            sum += dataItem.get('valueY')
          }
        })
        return `Total: [bold]${root.numberFormatter.format(sum)}[/]`
      })

      const data = resp.data.data
      let max = 0
      let min = 0
      data.forEach((row) => {
        max = Math.max(max, row.hires)
        min = Math.min(min, row.leaves)
      })

      const yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.grid.template.set('forceHidden', true)
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          categoryField: 'date',
          renderer: yRenderer,
          visible: false,
          min,
          max
        })
      )

      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          fill: am5.color(0x1B965C),
          xAxis,
          yAxis,
          valueYField: 'hires',
          valueXField: 'date',
          sequencedInterpolation: true,
          clustered: false,
          categoryYField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'Hired: {valueY}',
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )
      series.columns.template.setAll({
        height: am5.p100
      })

      const series2 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series',
          fill: am5.color(0xF21313),
          xAxis,
          yAxis,
          valueYField: 'leaves',
          valueXField: 'date',
          categoryYField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: 'Fired: {leaves_abs}',
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2
          })
        })
      )
      series2.columns.template.setAll({
        height: am5.p100
      })

      const seriesTotal = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          xAxis: xAxisTotal,
          yAxis,
          valueYField: 'b2b',
          valueXField: 'date'
        })
      )
      seriesTotal.columns.template.setAll({
        forceHidden: true
      })

      data.forEach((row) => {
        row.leaves_abs = row.leaves * -1
      })
      series.data.setAll(data)
      series2.data.setAll(data)
      seriesTotal.data.setAll(data)
      series.appear(1000)
      series2.appear(1000)
      seriesTotal.appear(1000)
      chart.appear(1000, 100)
    },
    initChartDonat(resp, chart, type) {
      const root = am5.Root.new(chart)
      root.setThemes([am5themes_Animated.new(root)])
      const container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        })
      )
      const title = type === 'people' ? 'People in projects' : 'Project cost'
      container.children.unshift(
        am5.Label.new(root, {
          text: title,
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          x: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        })
      )

      const series = container.children.push(
        am5hierarchy.Sunburst.new(root, {
          singleBranchOnly: true,
          downDepth: 10,
          initialDepth: 10,
          topDepth: 1,
          innerRadius: am5.percent(30),
          valueField: 'value',
          categoryField: 'name',
          childDataField: 'children'
        })
      )

      const data = []
      const projectMap = {}
      resp.data.projects.forEach((row) => {
        projectMap[row.ID] = row
      })
      const employeeMap = {}
      resp.data.employees.forEach((row) => {
        employeeMap[row.ID] = row
      })
      const departmentsMap = {}
      resp.data.departments.forEach((row) => {
        departmentsMap[row.ID] = row
      })
      const positionsMap = {}
      resp.data.positions.forEach((row) => {
        positionsMap[row.ID] = row
      })
      const employeePositionsMap = {}
      resp.data.employeePositions.forEach((row) => {
        employeePositionsMap[row.Employee.ID] = row
      })
      const mapping = {}

      resp.data.data.forEach((row) => {
        const project = projectMap[row.Project.ID]
        const level1Key = `level_1_${project.ID}`
        let level1 = mapping[level1Key]
        if (!level1) {
          level1 = {
            name: project.Name,
            children: []
          }
          mapping[level1Key] = level1
          data.push(level1)
        }
        const employeeID = row.Employee.ID
        const employee = employeeMap[employeeID]
        const employeePosition = employeePositionsMap[employeeID]
        if (!employeePosition) {
          console.warn(`missing position for ${employee.ID}`)
          return
        }
        const position = positionsMap[employeePosition.Position.ID]
        const department = departmentsMap[position.Department.ID]
        const level2Key = `${level1Key}_${department.ID}`
        let level2 = mapping[level2Key]
        if (!level2) {
          level2 = {
            name: department.Name,
            children: []
          }
          mapping[level2Key] = level2
          level1.children.push(level2)
        }

        const level3Key = `${level2Key}_${position.ID}`
        let level3 = mapping[level3Key]
        if (!level3) {
          level3 = {
            name: position.Name,
            children: []
          }
          mapping[level3Key] = level3
          level2.children.push(level3)
        }

        const level4Key = `${level3Key}_${employee.ID}`
        let level4 = mapping[level4Key]
        if (!level4) {
          let value = row.Availability / 100
          if (type === 'cost') {
            value = Math.round(value * employeePosition.SalaryGrossPLN)
          }
          level4 = {
            name: employee.Name,
            value
          }
          mapping[level4Key] = level4
          level3.children.push(level4)
        }
      })
      series.data.setAll([
        {
          name: 'root',
          children: data
        }
      ])

      series.selectDataItem(series.dataItems[0])
      series.appear(1000, 100)
    },
    initChartDonat2(resp, chart, type) {
      const root = am5.Root.new(chart)
      root.setThemes([am5themes_Animated.new(root)])
      const container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        })
      )
      const title = type === 'people' ? 'Positions' : 'Salaries'
      container.children.unshift(
        am5.Label.new(root, {
          text: title,
          fontSize: 11,
          fontWeight: '400',
          textAlign: 'center',
          x: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0
        })
      )

      const series = container.children.push(
        am5hierarchy.Sunburst.new(root, {
          singleBranchOnly: true,
          downDepth: 10,
          initialDepth: 10,
          topDepth: 1,
          innerRadius: am5.percent(30),
          valueField: 'value',
          categoryField: 'name',
          childDataField: 'children'
        })
      )

      const data = []
      const projectMap = {}
      resp.data.projects.forEach((row) => {
        projectMap[row.ID] = row
      })
      const employeeMap = {}
      resp.data.employees.forEach((row) => {
        employeeMap[row.ID] = row
      })
      const departmentsMap = {}
      resp.data.departments.forEach((row) => {
        departmentsMap[row.ID] = row
      })
      const positionsMap = {}
      resp.data.positions.forEach((row) => {
        positionsMap[row.ID] = row
      })
      const employeePositionsMap = {}
      resp.data.employeePositions.forEach((row) => {
        employeePositionsMap[row.Employee.ID] = row
      })
      const mapping = {}

      resp.data.employeePositions.forEach((row) => {
        const position = positionsMap[row.Position.ID]
        const department = departmentsMap[position.Department.ID]
        const level1Key = `level_1_${department.ID}`
        let level1 = mapping[level1Key]
        if (!level1) {
          level1 = {
            name: department.Name,
            children: []
          }
          mapping[level1Key] = level1
          data.push(level1)
        }
        const employeeID = row.Employee.ID
        const employee = employeeMap[employeeID]

        const level2Key = `${level1Key}_${position.ID}`
        let level2 = mapping[level2Key]
        if (!level2) {
          level2 = {
            name: position.Name,
            children: []
          }
          mapping[level2Key] = level2
          level1.children.push(level2)
        }

        const level3Key = `${level2Key}_${employee.ID}`
        let level3 = mapping[level3Key]
        const value = type === 'cost' ? row.SalaryGrossPLN : row.Availability / 100
        if (!level3) {
          level3 = {
            name: employee.Name,
            value
          }
          mapping[level3Key] = level3
          level2.children.push(level3)
        } else if (type === 'cost') {
          level3.value += value
        }
      })
      series.data.setAll([
        {
          name: 'root',
          children: data
        }
      ])

      series.selectDataItem(series.dataItems[0])
      series.appear(1000, 100)
    },
    initChartOneOne(resp) {
      const root = am5.Root.new('chartOneOne')
      root.setThemes([am5themes_Animated.new(root)])
      const container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        })
      )

      const series = container.children.push(
        am5hierarchy.Tree.new(root, {
          singleBranchOnly: false,
          downDepth: 1,
          initialDepth: 10,
          valueField: 'value',
          categoryField: 'name',
          childDataField: 'children'
        })
      )

      const data = {
        name: 'Gamesture',
        children: []
      }
      generateLevel(data, 0)

      const circleTemplate = am5.Template.new({})

      series.bullets.push((root, series, dataItem) => {
        if (!dataItem.dataContext.image) {
          return false
        }
        let tooltip = `<img src="${dataItem.dataContext.image.src}" style="height: 150px"/><br/><b>${dataItem.dataContext.name}</b>`
        if (dataItem.dataContext.value) {
          tooltip += `<br/>Subordinates: ${dataItem.dataContext.value}`
        }
        const bulletContainer = am5.Container.new(root, {})
        bulletContainer.children.push(
          am5.Circle.new(
            root,
            {
              radius: 34
            },
            circleTemplate
          )
        )

        const maskCircle = bulletContainer.children.push(am5.Circle.new(root, { radius: 20 }))

        // only containers can be masked, so we add image to another container
        const imageContainer = bulletContainer.children.push(
          am5.Container.new(root, {
            mask: maskCircle
          })
        )

        imageContainer.children.push(
          am5.Picture.new(root, {
            templateField: 'image',
            centerX: am5.p50,
            centerY: am5.p50,
            width: 40,
            height: 40,
            tooltipHTML: tooltip
          })
        )

        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: bulletContainer
        })
      })

      series.data.setAll([data])
      series.set('selectedDataItem', series.dataItems[0])

      function generateLevel(data, supervisorID) {
        let total = 0
        resp.data.data.forEach((user) => {
          if ((!user.OneOneSupervisor && !supervisorID) || user?.OneOneSupervisor?.ID === supervisorID) {
            total++
            const row = {
              name: user.Name,
              value: 0,
              image: { src: user.ProfilePicture },
              children: []
            }
            data.children.push(row)
            row.value = generateLevel(row, user.ID)
          }
        })
        return total
      }
      series.appear(1000, 100)
    },
    initChartHierarchy(resp) {
      const levelsOrder = ['Executive Director or CEO', 'President and Vice-President', 'Department director', 'Manager', 'Supervisor', 'Employee']
      const levels = {}
      levelsOrder.forEach((name, key) => {
        levels[name] = key
      })
      const treeLevels = {}
      for (let i = 0; i <= 5; i++) {
        treeLevels[i] = {}
      }

      const hierarchyIDsMapping = {}
      resp.data.data.forEach((row) => {
        const level = levels[row.Hierarchy.Level]
        treeLevels[level][row.ID] = row
        hierarchyIDsMapping[row.Hierarchy.ID] = row.Hierarchy
      })

      const data = [
        {
          name: 'Gamesture',
          children: [],
          image: { src: 'https://gamesture.com/assets/images/logo-green.svg' }
        }
      ]
      const mapping = {}
      for (let i = 0; i <= 5; i++) {
        for (const k in treeLevels[i]) {
          const row = treeLevels[i][k]
          const mappingKey = `${i}_${row.Employee.ID}`
          let name = `${row.Employee.Name} - ${row.Hierarchy.Name}`
          if (row.Project) {
            name += ` - ${row.Project.Name}`
          }
          const node = {
            name,
            image: { src: row.Employee.ProfilePicture },
            row,
            id: row.ID,
            value: 1,
            children: [],
            link: []
          }
          if (i === 0) {
            mapping[mappingKey] = node
            data[0].children.push(node)
            continue
          }
          const supervisor = row.SupervisorHierarchy
          if (supervisor === null) {
            let children = data[0].children
            let currentLevel = 0
            while (currentLevel < i) {
              if (!Object.keys(treeLevels[currentLevel]).length) {
                currentLevel++
                continue
              }
              let properChild = null
              for (const child of children) {
                if (child.id === 0) {
                  properChild = child
                  break
                }
              }
              if (properChild === null) {
                properChild = {
                  name: `company level:  ${levelsOrder[currentLevel]}`,
                  id: 0,
                  value: 0,
                  image: { src: 'https://gamesture.com/assets/images/logo-green.svg' },
                  children: []
                }
                mapping[`${currentLevel}_0`] = properChild
                children.push(properChild)
              }
              children = properChild.children
              currentLevel++
            }
            mapping[mappingKey] = node
            children.push(node)
            continue
          }
          const parentMappingKey = `${levels[hierarchyIDsMapping[supervisor.Hierarchy.ID].Level]}_${supervisor.Employee.ID}`
          const parent = mapping[parentMappingKey]
          if (parent === undefined) {
            console.warn('missing parent', row, parentMappingKey, supervisor)
            continue
          }
          const supervisorLevel = levels[hierarchyIDsMapping[supervisor.Hierarchy.ID].Level]
          const diff = i - supervisorLevel
          if (diff > 1) {
            let children = parent.children
            for (let z = supervisorLevel + 1; z < i; z++) {
              const connectionKey = `${supervisor.ID}_${z}_0`
              let connection = mapping[connectionKey]
              if (connection === undefined) {
                connection = {
                  name: `company level:  ${levelsOrder[z]}`,
                  id: 0,
                  value: 0,
                  image: { src: 'https://gamesture.com/assets/images/logo-green.svg' },
                  children: []
                }
                mapping[connectionKey] = connection
                children.push(connection)
              }
              children = connection.children
            }
            children.push(node)
            mapping[mappingKey] = node
          } else {
            parent.children.push(node)
            mapping[mappingKey] = node
          }
        }
      }

      const root = am5.Root.new('chartHierarchy')
      root.setThemes([am5themes_Animated.new(root)])
      const container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout
        })
      )

      const series = container.children.push(
        am5hierarchy.Tree.new(root, {
          downDepth: 1,
          initialDepth: 6,
          valueField: 'value',
          categoryField: 'name',
          idField: 'name',
          childDataField: 'children',
          linkWithField: 'link'
        })
      )

      const circleTemplate = am5.Template.new({})

      series.bullets.push((root, series, dataItem) => {
        if (!dataItem.dataContext.image) {
          return false
        }
        let tooltip = `<img src="${dataItem.dataContext.image.src}" style="height: 150px"/><br/><b>${dataItem.dataContext.name}</b>`
        if (dataItem.dataContext.row) {
          tooltip += '<br/><hr><br/>'
          tooltip += `<b>Level: </b>${dataItem.dataContext.row.Hierarchy.Level}<br/>`
          if (dataItem.dataContext.row.Hierarchy.Responsibilities) {
            tooltip += `<b>Responsibilities: </b><br/>${dataItem.dataContext.row.Hierarchy.Responsibilities}`
          }
        }
        const bulletContainer = am5.Container.new(root, {})
        bulletContainer.children.push(
          am5.Circle.new(
            root,
            {
              radius: 34
            },
            circleTemplate
          )
        )

        const maskCircle = bulletContainer.children.push(am5.Circle.new(root, { radius: 20 }))

        // only containers can be masked, so we add image to another container
        const imageContainer = bulletContainer.children.push(
          am5.Container.new(root, {
            mask: maskCircle
          })
        )

        imageContainer.children.push(
          am5.Picture.new(root, {
            templateField: 'image',
            centerX: am5.p50,
            centerY: am5.p50,
            width: 40,
            height: 40,
            tooltipHTML: tooltip
          })
        )

        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: bulletContainer
        })
      })

      series.data.setAll(data)
      series.set('selectedDataItem', series.dataItems[0])
    }
  }
}
</script>

<template>
  <el-row class="m-5">
    <el-tabs
      v-model="tab"
      class="w-full"
      @tab-change="tabChanged"
    >
      <el-tab-pane
        label="Employees"
        name="list"
      >
        <crud-table
          :default-visible-columns="['Name', 'Department', 'Position', 'Project', 'OneOneSupervisor']"
          api="settings/employees"
          api-item="settings/employee"
          show-img-preview-column
          :versioned="false"
          :enable-cell-edit-dialogs="false"
          :top-actions="false"
          :columns-settings="{
            Name: { width: 260 },
            Department: { width: 220 },
            Position: { width: 310 },
            Project: { width: 220 },
            OneOneSupervisor: { width: 260, header: 'One-One' }
          }"
          :actions-on-list="{
            crudDelete: { right: 'none' },
            crudPreview: { label: 'details', withTooltip: true, link: true, right: 'user', elAttr: { /*type: '', */ class: 'gs-btn-outlined-primary-light ml-0.5', icon: View } }
          }"
        >
          <template #cell_Position="{ row }">
            <span v-if="row.current_position">
              <el-tag
                v-if="row.current_position.Level !== 'none'"
                :type="row.current_position.Level === 'junior' ? 'info' : row.current_position.Level === 'mid' ? '' : 'success'"
                effect="plain"
                size="small"
                class="mr-2"
              >{{ row.current_position.Level }}</el-tag>
              <span>{{ row.current_position.PositionName === '' ? row.Position.Name : row.current_position.PositionName }}</span>
            </span>
          </template>
          <template #cell_Project="{ row }">
            <el-tooltip
              v-for="project in row.projects"
              :key="project.ID"
              effect="light"
              :content="`Availability: ${project.Availability}%`"
            >
              <el-tag
                size="small"
                :type="project.Availability === 100 ? 'success' : 'info'"
              >
                {{ project.Name }}
              </el-tag>
            </el-tooltip>
          </template>
          <template #cell_Archived="{ row }">
            {{ row.current_position ? 'no' : 'yes' }}
          </template>
          <template #cell_OneOneSupervisor="{ row }">
            <span v-if="row.OneOneSupervisor">
              {{ row.OneOneSupervisor.AdminLabel.split(':')[1].trim() }}
              <el-tag
                v-if="!row.oneOne"
                type="danger"
                size="small"
              >one-one required</el-tag>
              <template v-else>
                <el-tag
                  v-if="Date.now() - Date.parse(row.oneOne.Date) > 15552000000"
                  type="danger"
                  size="small"
                >{{ timeAgo.format(Date.parse(row.oneOne.Date)) }}</el-tag>
                <el-tag
                  v-else-if="Date.now() - Date.parse(row.oneOne.Date) > 2592000000"
                  type="warning"
                  size="small"
                >{{ timeAgo.format(Date.parse(row.oneOne.Date)) }}</el-tag>
                <el-tag
                  v-else
                  size="small"
                >{{ timeAgo.format(Date.parse(row.oneOne.Date)) }}</el-tag>
              </template>
            </span>
          </template>
        </crud-table>
      </el-tab-pane>
      <!--      <el-tab-pane -->
      <!--        label="Hierarchy" -->
      <!--        name="hierarchy" -->
      <!--      > -->
      <!--        <el-row> -->
      <!--          <el-col> -->
      <!--            <div -->
      <!--              id="chartHierarchy" -->
      <!--              style="height: 600px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--        </el-row> -->
      <!--      </el-tab-pane> -->
      <!--      <el-tab-pane -->
      <!--        label="OneOne structure" -->
      <!--        name="one-one" -->
      <!--      > -->
      <!--        <el-row> -->
      <!--          <el-col> -->
      <!--            <div -->
      <!--              id="chartOneOne" -->
      <!--              style="height: 600px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--        </el-row> -->
      <!--      </el-tab-pane> -->
      <!--      <el-tab-pane -->
      <!--        label="Statistics" -->
      <!--        name="statistics" -->
      <!--      > -->
      <!--        <el-row> -->
      <!--          <el-col :span="8"> -->
      <!--            <div -->
      <!--              id="chart1" -->
      <!--              style="height: 250px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--          <el-col :span="8"> -->
      <!--            <div -->
      <!--              id="chart2" -->
      <!--              style="height: 250px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--          <el-col :span="8"> -->
      <!--            <div -->
      <!--              id="chart3" -->
      <!--              style="height: 250px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--        </el-row> -->
      <!--        <el-row> -->
      <!--          <el-col :span="12"> -->
      <!--            <div -->
      <!--              id="chart4" -->
      <!--              style="height: 400px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--          <el-col :span="12"> -->
      <!--            <div -->
      <!--              id="chart5" -->
      <!--              style="height: 400px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--        </el-row> -->
      <!--        <el-row class="mt-10"> -->
      <!--          <el-col :span="12"> -->
      <!--            <div -->
      <!--              id="chart6" -->
      <!--              style="height: 450px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--          <el-col :span="12"> -->
      <!--            <div -->
      <!--              id="chart7" -->
      <!--              style="height: 450px" -->
      <!--            /> -->
      <!--          </el-col> -->
      <!--        </el-row> -->
      <!--      </el-tab-pane> -->
    </el-tabs>
  </el-row>
</template>
