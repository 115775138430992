<script>
import CrudFieldSelectorEmployee from '@/components/forms/crud-fields/CrudFieldSelectorEmployee.vue'
import CrudFieldSelectorProject from '@/components/forms/crud-fields/CrudFieldSelectorProject.vue'

export default {
  components: { CrudFieldSelectorEmployee, CrudFieldSelectorProject }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-employee-projects']"
    api="settings/employee-project"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Employee -->
        <CrudFieldSelectorEmployee
          api-field-name="Employee"
          required
        />
        <!-- Project -->
        <CrudFieldSelectorProject
          api-field-name="Project"
          required
        />
        <!-- Availability -->
        <crud-field-number
          api-field-name="Availability"
          label="Availability (%)"
          :min="1"
          :max="100"
          required
        />
      </fields-col>
      <fields-col :span="8">
        <!-- Started -->
        <crud-field-date
          api-field-name="Started"
          required
        />
        <!-- Ended -->
        <crud-field-date api-field-name="Ended" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
