<template>
  <crud-table
    :default-visible-columns="['Name', 'Email', 'Archived']"
    api="settings/employees"
    api-item="settings/employee"
    show-img-preview-column
    :versioned="false"
  >
    <template #cell_Archived="{ row }">
      {{ row.current_position ? 'no' : 'yes' }}
    </template>
  </crud-table>
</template>
