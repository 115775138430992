<template>
  <crud-details-page
    :back-routes="['settings-resources']"
    api="settings/resource"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- Description -->
        <crud-field-textarea
          api-field-name="Description"
          required
        />
      </fields-col>
      <fields-col :span="8">
        <!-- AddProcess -->
        <crud-field-textarea
          api-field-name="AddProcess"
          required
        />
      </fields-col>
      <fields-col :span="8">
        <!-- RemoveProcess -->
        <crud-field-textarea
          api-field-name="RemoveProcess"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
