import { defineAsyncComponent } from 'vue'
import { CrudRoute, MenuItem } from '!/router'
import { userRightRoles } from '!/composition/utilities'

const dashRoutes = [
  CrudRoute.buildRoute('dashboard', () => import('@/pages/index/IndexPage.vue'), 'Dashboard', 'Dash', 'ep:data-board')
    .setCssClass('scale-90')
    .getData()
]

const oneOneRoutes = [
  ...CrudRoute.buildRoutes(
    'one-ones',
    () => import('@/pages/one-one/IndexPage.vue'),
    () => import('@/pages/one-one/DetailsPage.vue'),
    'One-One',
    'One-One',
    'fluent:person-feedback-16-regular',
    'entities.OneOne'
  )
    .setAccessRole(userRightRoles.admin)
    .getData()
]

const publicRelationsRoutes = [
  ...CrudRoute.buildRoutes(
    'public-relations',
    () => import('@/pages/public-relations/IndexPage.vue'),
    () => import('@/pages/public-relations/DetailsPage.vue'),
    'Public Relations',
    'Public Relation',
    'simple-line-icons:people',
    'entities.PublicRelation'
  )
    .setAccessRole(userRightRoles.support)
    .getData()
]

const settingsRoutes = [
  CrudRoute.buildRoute(
    'dashboard-details',
    () => import('@/pages/index/EmployeePage.vue'),
    'Employee',
    'Employee',
    'ep:data-board',
    'entities.EmployeePosition',
    defineAsyncComponent(() => import('@/components/selectors/EmployeeSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .setIsMenuItem(false)
    .setPath('/employee/:id(\\d+)')
    .setCssClass('scale-90')
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-employees',
    () => import('@/pages/settings/employees/IndexPage.vue'),
    () => import('@/pages/settings/employees/DetailsPage.vue'),
    'Employees',
    'Employee',
    'ant-design:user-outlined',
    'entities.Employee',
    defineAsyncComponent(() => import('@/components/selectors/EmployeeSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-employee-positions',
    () => import('@/pages/settings/employee-positions/IndexPage.vue'),
    () => import('@/pages/settings/employee-positions/DetailsPage.vue'),
    'Employee positions',
    'Employee position',
    'cil:badge',
    'entities.EmployeePosition'
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-employee-projects',
    () => import('@/pages/settings/employee-projects/IndexPage.vue'),
    () => import('@/pages/settings/employee-projects/DetailsPage.vue'),
    'Project assignments',
    'Project assignment',
    'eos-icons:role-binding-outlined',
    'entities.EmployeeProject'
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-projects',
    () => import('@/pages/settings/projects/IndexPage.vue'),
    () => import('@/pages/settings/projects/DetailsPage.vue'),
    'Projects',
    'Project',
    'fluent:people-team-20-regular',
    'entities.Project',
    defineAsyncComponent(() => import('@/components/selectors/ProjectSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-positions',
    () => import('@/pages/settings/positions/IndexPage.vue'),
    () => import('@/pages/settings/positions/DetailsPage.vue'),
    'Positions',
    'Position',
    'eos-icons:role-binding-outlined',
    'entities.Position',
    defineAsyncComponent(() => import('@/components/selectors/PositionSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-departments',
    () => import('@/pages/settings/departments/IndexPage.vue'),
    () => import('@/pages/settings/departments/DetailsPage.vue'),
    'Departments',
    'Department',
    'carbon:tag-group',
    'entities.Department',
    defineAsyncComponent(() => import('@/components/selectors/DepartmentSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-hierarchies',
    () => import('@/pages/settings/hierarchies/IndexPage.vue'),
    () => import('@/pages/settings/hierarchies/DetailsPage.vue'),
    'Hierarchy',
    'Hierarchy',
    'system-uicons:hierarchy',
    'entities.Hierarchy',
    defineAsyncComponent(() => import('@/components/selectors/HierarchySelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-hierarchy-lines',
    () => import('@/pages/settings/hierarchy-lines/IndexPage.vue'),
    () => import('@/pages/settings/hierarchy-lines/DetailsPage.vue'),
    'Hierarchy lines',
    'Hierarchy line',
    'material-symbols:line-start-square-outline',
    'entities.HierarchyLine',
    defineAsyncComponent(() => import('@/components/selectors/HierarchyLineSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-resources',
    () => import('@/pages/settings/resources/IndexPage.vue'),
    () => import('@/pages/settings/resources/DetailsPage.vue'),
    'Resources',
    'Resource',
    'tabler:lock-access',
    'entities.Resource',
    defineAsyncComponent(() => import('@/components/selectors/ResourceSelector.vue'))
  )
    .setAccessRole(userRightRoles.support)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-employee-resources',
    () => import('@/pages/settings/employee-resources/IndexPage.vue'),
    () => import('@/pages/settings/employee-resources/DetailsPage.vue'),
    'Employee resources',
    'Employee resource',
    'uis:lock-access',
    'entities.EmployeeResource'
  )
    .setAccessRole(userRightRoles.support)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-position-resources',
    () => import('@/pages/settings/position-resource/IndexPage.vue'),
    () => import('@/pages/settings/position-resource/DetailsPage.vue'),
    'Position resources',
    'Position resource',
    'uil:lock-access',
    'entities.PositionResource'
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-resource-admins',
    () => import('@/pages/settings/resource-admins/IndexPage.vue'),
    () => import('@/pages/settings/resource-admins/DetailsPage.vue'),
    'Resource admins',
    'Resource admin',
    'uil:lock-access',
    'entities.ResourceAdmin'
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-hardware-types',
    () => import('@/pages/settings/hardware-types/IndexPage.vue'),
    () => import('@/pages/settings/hardware-types/DetailsPage.vue'),
    'Hardware types',
    'Hardware type',
    'game-icons:pc',
    'entities.HardwareType',
    defineAsyncComponent(() => import('@/components/selectors/HardwareTypeSelector.vue'))
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'settings-hardware',
    () => import('@/pages/settings/hardware/IndexPage.vue'),
    () => import('@/pages/settings/hardware/DetailsPage.vue'),
    'Hardware list',
    'Hardware',
    'game-icons:pc',
    'entities.Hardware'
  )
    .setAccessRole(userRightRoles.admin)
    .getData(),
  ...CrudRoute.buildRoutes(
    'admin-users',
    () => import('!/pages/settings/admin-users/IndexPage.vue'),
    () => import('!/pages/settings/admin-users/DetailsPage.vue'),
    'Admin users',
    'Admin user',
    'clarity:administrator-line',
    'adminCore.AccessRightEntity'
  )
    .setAccessRole(userRightRoles.admin)
    .getData()
]

export default () => {
  return [
    MenuItem.build('dashboard', dashRoutes).setIsSubMenu(false).getData(),
    MenuItem.build('one-ones', oneOneRoutes).setIsSubMenu(false).getData(),
    MenuItem.build('public-relations', publicRelationsRoutes).setIsSubMenu(false).getData(),
    MenuItem.build('settings', settingsRoutes, 'arcticons:settings', 'Settings').getData()
  ]
}
