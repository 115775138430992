<script>
import CrudFieldSelectorHierarchy from '@/components/forms/crud-fields/CrudFieldSelectorHierarchy.vue'
import CrudFieldSelectorHierarchyLine from '@/components/forms/crud-fields/CrudFieldSelectorHierarchyLine.vue'

export default {
  components: { CrudFieldSelectorHierarchyLine, CrudFieldSelectorHierarchy }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-hierarchy-lines']"
    api="settings/hierarchy-line"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <CrudFieldSelectorHierarchy
          api-field-name="Hierarchy"
          required
        />
        <crud-field-selector-employee
          api-field-name="Employee"
          required
        />
        <CrudFieldSelectorHierarchyLine
          api-field-name="SupervisorHierarchy"
        />
      </fields-col>
      <fields-col :span="8">
        <!-- Started -->
        <crud-field-date
          api-field-name="Started"
          required
        />
        <!-- Ended -->
        <crud-field-date api-field-name="Ended" />
      </fields-col>
      <fields-col :span="8">
        <!-- Project -->
        <crud-field-selector-project api-field-name="Project" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
