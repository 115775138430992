<template>
  <crud-details-page
    :back-routes="['settings-projects']"
    api="settings/project"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
