<script>
import CrudFieldSelectorHardwareType from '@/components/forms/crud-fields/CrudFieldSelectorHardwareType.vue'

export default {
  components: { CrudFieldSelectorHardwareType }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-hardware']"
    api="settings/hardware"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Type -->
        <CrudFieldSelectorHardwareType
          api-field-name="Type"
          required
        />
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- YearOfProduction -->
        <crud-field-number
          api-field-name="YearOfProduction"
          required
        />
        <!-- SerialNumber -->
        <crud-field-text
          api-field-name="SerialNumber"
        />
        <!-- AssignedTo -->
        <crud-field-selector-employee
          api-field-name="AssignedTo"
        />
      </fields-col>
      <fields-col :span="16">
        <!-- Specification -->
        <crud-field-textarea
          api-field-name="Specification"
          :textarea-rows="12.3"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
