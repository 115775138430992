<script>
import CrudFieldSelectorEmployee from '@/components/forms/crud-fields/CrudFieldSelectorEmployee.vue'
import CrudFieldSelectorPosition from '@/components/forms/crud-fields/CrudFieldSelectorPosition.vue'

export default {
  components: { CrudFieldSelectorPosition, CrudFieldSelectorEmployee }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-employee-positions']"
    api="settings/employee-position"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Employee -->
        <CrudFieldSelectorEmployee
          api-field-name="Employee"
          required
        />
        <!-- Position -->
        <CrudFieldSelectorPosition
          api-field-name="Position"
          required
        />
        <!-- PositionName -->
        <crud-field-text api-field-name="PositionName" />
        <!-- Level -->
        <crud-field-select
          api-field-name="Level"
          options-enum="enums.PositionLevel"
          required
        />
      </fields-col>
      <fields-col :span="8">
        <!-- Contract -->
        <crud-field-select
          api-field-name="Contract"
          options-enum="enums.Contract"
          required
        />
        <!-- Availability -->
        <crud-field-number
          api-field-name="Availability"
          label="Availability (%)"
          :min="1"
          :max="100"
          required
        />
        <!-- Started -->
        <crud-field-date
          api-field-name="Started"
          required
        />
        <fields-col>
          <fields-col :span="16">
            <!-- Ended -->
            <crud-field-date api-field-name="Ended" />
          </fields-col>
          <fields-col :span="8">
            <!-- Trial -->
            <crud-field-switcher api-field-name="Trial" />
          </fields-col>
        </fields-col>
      </fields-col>
      <fields-col :span="8">
        <!-- SalaryGrossPLN -->
        <crud-field-number
          api-field-name="SalaryGrossPLN"
          label="Salary gross PLN"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
