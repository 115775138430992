<template>
  <crud-details-page
    :back-routes="['one-ones']"
    api="one-one"
    :versioned="false"
    disable-top-margin
  >
    <template #form="{ form }">
      <fields-col :span="6">
        <!-- Date -->
        <crud-field-date
          api-field-name="Date"
          required
        />
      </fields-col>
      <fields-col :span="6">
        <!-- Employee -->
        <crud-field-selector-employee
          api-field-name="Employee"
          required
        />
      </fields-col>
      <fields-col :span="6">
        <!-- OneOneSupervisor -->
        <crud-field-selector-employee
          api-field-name="OneOneSupervisor"
          required
        />
      </fields-col>
      <fields-col :span="6">
        <crud-field-slot>
          <template #label>
            Links
          </template>
          <template #default>
            <el-link
              v-if="form.ID"
              type="primary"
              :href="`/employee/${form.Employee.ID}`"
              target="_blank"
            >
              Employee profile
            </el-link> |
            <el-link
              v-if="form.ID"
              type="primary"
              :href="`/one-ones?c_fil%5BEmployee%5D=${form.Employee.ID}`"
              target="_blank"
            >
              Previous one-ones
            </el-link>
          </template>
        </crud-field-slot>
      </fields-col>
      <fields-col :span="6">
        <!-- Comment -->
        <crud-field-textarea
          api-field-name="Comment"
          label="General comments"
        />
        <!-- CommentSalary -->
        <crud-field-textarea
          api-field-name="CommentSalary"
          label="Salary comments"
        />
      </fields-col>
      <fields-col :span="6">
        <!-- PositiveFeedbackEmployee -->
        <crud-field-textarea
          api-field-name="PositiveFeedbackEmployee"
          label="Positive feedback from employee"
        />
        <!-- NegativeFeedbackEmployee -->
        <crud-field-textarea
          api-field-name="NegativeFeedbackEmployee"
          label="Negative feedback from employee"
        />
      </fields-col>
      <fields-col :span="6">
        <!-- PositiveFeedbackSupervisor -->
        <crud-field-textarea
          api-field-name="PositiveFeedbackSupervisor"
          label="Positive feedback from supervisor"
        />
        <!-- NegativeFeedbackSupervisor -->
        <crud-field-textarea
          api-field-name="NegativeFeedbackSupervisor"
          label="Negative feedback from supervisor"
        />
      </fields-col>
      <fields-col :span="6">
        <!-- TodoEmployee -->
        <crud-field-textarea
          api-field-name="TodoEmployee"
          label="TODO employee"
        />
        <!-- TodoSupervisor -->
        <crud-field-textarea
          api-field-name="TodoSupervisor"
          label="TODO supervisor"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
