<script>
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { Close, Plus, QuestionFilled, View } from '@element-plus/icons-vue'

export default {
  setup() {
    TimeAgo.addDefaultLocale(en)
    const timeAgo = new TimeAgo('en-US')
    return {
      timeAgo,
      View,
      Plus,
      Close,
      QuestionFilled
    }
  },
  data() {
    return {
      showSalary: false,
      showHiddenResources: false,
      hired: '',
      data: {},
      positions: [],
      projects: [],
      oneOnes: [],
      resources: [],
      allResources: [],
      positionResources: [],
      resourceAdmins: [],
      hardware: []
    }
  },
  computed: {
    position() {
      if (this.positions.length === 0) {
        return null
      }
      return this.positions[0]
    },
    hiredDate() {
      if (this.positions.length === 0) {
        return null
      }
      return this.positions[this.positions.length - 1].Started
    },
    usedResources() {
      const userEmail = this.$store.getters['auth/userData'].Email
      const resources = []
      this.allResources.forEach((resource) => {
        const data = {
          id: resource.ID,
          name: resource.Name,
          description: resource.Description,
          addProcess: resource.AddProcess,
          removeProcess: resource.RemoveProcess,
          whoCanAdd: resource.WhoCanAdd,
          whoCanRemove: resource.WhoCanRemove,
          inPosition: false,
          actual: null,
          isAdmin: false,
          admins: []
        }
        this.positionResources?.forEach((row) => {
          if (row.Resource.ID === resource.ID) {
            data.inPosition = true
          }
        })
        this.resources?.forEach((row) => {
          if (row.Resource.ID === resource.ID) {
            data.actual = row
          }
        })
        this.resourceAdmins?.forEach((row) => {
          if (row.Resource.ID === resource.ID) {
            data.admins.push(row.Admin.Name)
            if (row.Admin.Email === userEmail) {
              data.isAdmin = true
            }
          }
        })
        if (this.showHiddenResources || data.inPosition || data.actual) {
          resources.push(data)
        }
      })
      resources.sort((a, b) => {
        if (a.inPosition && !b.inPosition) {
          return -1
        }
        return 0
      })
      return resources
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    grant(resource_id, action) {
      this.$axios
        .post('/admin/api/resource/assign/', {
          resource_id,
          employee_id: Number.parseInt(this.$route.params.id),
          action
        })
        .then(() => {
          this.loadData()
        })
        .catch(this.$utils.catchError)
    },
    loadData() {
      this.$axios.get('/admin/api/employee/', { params: { id: this.$route.params.id } }).then((resp) => {
        this.data = resp.data.data
        this.positions = resp.data.positions
        this.projects = resp.data.projects
        this.oneOnes = resp.data.oneOnes
        this.resources = resp.data.resources
        this.allResources = resp.data.allResources
        this.positionResources = resp.data.positionResources
        this.resourceAdmins = resp.data.resourceAdmins
        this.showSalary = resp.data.showSalary
        this.hardware = resp.data.hardware
      })
    }
  }
}
</script>

<template>
  <el-row class="mt-5">
    <el-col :span="8">
      <el-card
        :header="data.Name"
        class="ml-5 mr-5"
      >
        <el-descriptions
          direction="vertical"
          :column="2"
        >
          <el-descriptions-item
            label-class-name="font-bold"
            label="Email"
          >
            {{ data.Email }}
          </el-descriptions-item>
          <el-descriptions-item
            v-if="hiredDate"
            label-class-name="font-bold"
            label="Hired"
          >
            <el-tooltip
              effect="light"
              :content="timeAgo.format(Date.parse(hiredDate))"
            >
              {{ hiredDate.substring(0, 10) }}
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item
            label-class-name="font-bold"
            label="One-One supervisor"
          >
            {{ data.OneOneSupervisor?.Name }}
          </el-descriptions-item>
        </el-descriptions>
        <el-image
          v-if="data.ProfilePicture"
          style="width: 100%"
          :src="data.ProfilePicture"
        />
      </el-card>
      <el-card
        v-if="oneOnes && oneOnes.length"
        header="One-One"
        class="ml-5 mt-5 mr-5"
      >
        <el-table
          :data="oneOnes"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="Date"
            label="Date"
            width="200"
          >
            <template #default="scope">
              <span>{{ scope.row.Date.substring(0, 10) }}, {{ timeAgo.format(Date.parse(scope.row.Date)) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Supervisor"
            label="Supervisor"
          >
            <template #default="scope">
              <span>{{ scope.row.OneOneSupervisor.Name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            label=""
            width="33"
          >
            <template #default="scope">
              <router-link
                :to="`/one-ones/details/${scope.row.ID}`"
                target="_blank"
              >
                <el-button
                  :icon="View"
                  circle
                  size="small"
                />
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
    <el-col :span="16">
      <el-card
        header="Positions"
        class="mr-5"
      >
        <el-table
          :data="positions"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="Started"
            label="Started"
            width="110"
          >
            <template #default="scope">
              <span>{{ scope.row.Started.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Ended"
            label="Ended"
            width="110"
          >
            <template #default="scope">
              <span>{{ scope.row.Ended?.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showSalary"
            prop="Contract"
            label="Contract"
            width="90"
          >
            <template #default="scope">
              <span>{{ scope.row.Contract === 'b2b' ? 'b2b' : 'coe' }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Department"
            label="Department"
            width="120"
          >
            <template #default="scope">
              <span>{{ scope.row.Position.Department.Name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="showSalary"
            prop="Salary"
            label="Salary"
            width="120"
          >
            <template #default="scope">
              <span>{{ scope.row.SalaryGrossPLN }} PLN</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Position"
            label="Position"
          >
            <template #default="scope">
              <span>{{ scope.row.PositionName ? scope.row.PositionName : scope.row.Position.Name }}</span>
              <el-tag
                v-if="!scope.row.Ended || new Date(scope.row.Ended).getTime() > Date.now()"
                size="small"
                type="success"
                class="mx-1"
              >
                current
              </el-tag>
              <el-tag
                v-if="scope.row.Level !== 'none'"
                size="small"
                class="mx-1"
              >
                {{ scope.row.Level }}
              </el-tag>
              <el-tag
                v-if="scope.row.Trial"
                size="small"
                type="danger"
                class="mx-1"
              >
                trial
              </el-tag>
              <el-tag
                v-if="scope.row.Availability < 100"
                size="small"
                class="mx-1"
              >
                part time {{ scope.row.scope.row.Availability }}%
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card
        header="Projects"
        class="mt-5 mr-5"
      >
        <el-table
          :data="projects"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="Started"
            label="Started"
            width="110"
          >
            <template #default="scope">
              <span>{{ scope.row.Started.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Ended"
            label="Ended"
            width="110"
          >
            <template #default="scope">
              <span>{{ scope.row.Ended?.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Availability"
            label="Availability"
            width="110"
          >
            <template #default="scope">
              <span>{{ scope.row.Availability }} %</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Project"
            label="Project"
          >
            <template #default="scope">
              <span>{{ scope.row.Project.Name }}</span>
              <el-tag
                v-if="!scope.row.Ended || Date.format(scope.row.Ended).getTime() > Date.now()"
                size="small"
                class="mx-1"
                type="success"
              >
                current
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card
        header="hardware"
        class="mt-5 mr-5"
      >
        <el-table
          :data="hardware"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="Type"
            label="Type"
            width="200"
          >
            <template #default="scope">
              <span>{{ scope.row.Type.Name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Name"
            label="Name"
          >
            <template #default="scope">
              <span>{{ scope.row.Name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="YearOfProduction"
            label="Year"
            width="90"
          >
            <template #default="scope">
              <span>{{ scope.row.YearOfProduction }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Specification"
            label=""
            width="40"
          >
            <template #default="scope">
              <el-tooltip effect="light">
                <template #content>
                  <div style="width: 500px;">
                    <pre>{{ scope.row.Specification }}</pre>
                    <div
                      v-if="scope.row.SerialNumber"
                      class="pt-5"
                    >
                      <b>Serial number: </b>{{ scope.row.SerialNumber }}
                    </div>
                  </div>
                </template>
                <icon-ify
                  icon="mdi:chat-help-outline"
                  class="h-8 w-8 inline"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card
        header="Resources"
        class="mt-5 mr-5"
      >
        <template #header>
          <div class="card-header">
            <span>Resources</span>
            <el-tooltip
              effect="light"
              content="show all resources"
            >
              <el-switch
                v-model="showHiddenResources"
                size="small"
              />
            </el-tooltip>
          </div>
        </template>
        <el-table
          :data="usedResources"
          style="width: 100%"
          size="small"
        >
          <el-table-column
            prop="Resource"
            label="Resource"
            width="280"
          >
            <template #default="scope">
              <el-tooltip
                :content="scope.row.description.replaceAll('\n', '<br>')"
                placement="right"
                raw-content
                effect="light"
              >
                <span :style="{ 'font-weight': scope.row.inPosition ? 'bold' : '' }">{{ scope.row.name }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="GrantedAt"
            label="Granted at"
            width="110"
          >
            <template #default="scope">
              <span v-if="scope.row.actual">{{ scope.row.actual.GrantedAt?.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="GrantedBy"
            label="Granted by"
            width="170"
          >
            <template #default="scope">
              <span v-if="scope.row.actual">{{ scope.row.actual.GrantedBy.Name }}</span>
              <el-tooltip
                v-if="!scope.row.actual"
                effect="light"
                content="mark as granted"
              >
                <el-button
                  type="primary"
                  :disabled="!scope.row.isAdmin"
                  :icon="Plus"
                  size="small"
                  circle
                  @click="grant(scope.row.id, 'grant')"
                />
              </el-tooltip>
              <el-tooltip
                v-if="!scope.row.actual"
                effect="light"
              >
                <template #content>
                  <div style="width: 350px;">
                    <pre>{{ scope.row.addProcess }}</pre>
                    <el-divider>admins</el-divider>
                    <ul>
                      <li
                        v-for="admin in scope.row.admins"
                        :key="admin"
                      >
                        {{ admin }}
                      </li>
                    </ul>
                  </div>
                </template>
                <icon-ify
                  icon="mdi:chat-help-outline"
                  class="h-8 w-8 inline"
                />
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="RevokedAt"
            label="Revoked at"
            width="110"
          >
            <template #default="scope">
              <span v-if="scope.row.actual">{{ scope.row.actual.RevokedAt?.substring(0, 10) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="RevokedBy"
            label="Revoked by"
            width="170"
          >
            <template #default="scope">
              <span v-if="scope.row.actual && scope.row.actual.RevokedBy">{{ scope.row.actual.RevokedBy.Name }}</span>
              <el-tooltip
                v-if="scope.row.actual && !scope.row.actual.RevokedBy"
                effect="light"
              >
                <template #content>
                  <div style="width: 350px;">
                    <div class="mb-2">
                      Click to mark as revoked.
                    </div>
                    <el-divider>admins</el-divider>
                    <ul>
                      <li
                        v-for="admin in scope.row.admins"
                        :key="admin"
                      >
                        {{ admin }}
                      </li>
                    </ul>
                  </div>
                </template>
                <el-button
                  type="danger"
                  :disabled="!scope.row.isAdmin"
                  :icon="Close"
                  size="small"
                  circle
                  @click="grant(scope.row.id, 'revoke')"
                />
              </el-tooltip>
              <el-tooltip
                v-if="scope.row.actual && !scope.row.actual.RevokedBy"
                raw-content
                :content="scope.row.removeProcess.replaceAll('\n', '<br>')"
                effect="light"
              >
                <icon-ify
                  icon="mdi:chat-help-outline"
                  class="h-8 w-8 inline"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
  </el-row>
</template>

<style>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
