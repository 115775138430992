<template>
  <crud-table
    :default-visible-columns="['Position', 'Resource']"
    :columns-settings="{
      Position: { width: 250 },
      Resource: { width: 250 }
    }"
    api="settings/position-resources"
    api-item="settings/position-resource"
    :versioned="false"
  />
</template>
