<template>
  <crud-table
    :default-visible-columns="['Hierarchy', 'Employee', 'SupervisorHierarchy', 'Project']"
    api="settings/hierarchy-lines"
    api-item="settings/hierarchy-line"
    :versioned="false"
    :columns-settings="{
      Hierarchy: { width: 220 }
    }"
  />
</template>
