<script>
import CrudFieldSelectorPosition from '@/components/forms/crud-fields/CrudFieldSelectorPosition.vue'
import CrudFieldSelectorResource from '@/components/forms/crud-fields/CrudFieldSelectorResource.vue'

export default {
  components: { CrudFieldSelectorPosition, CrudFieldSelectorResource }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-position-resources']"
    api="settings/position-resource"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Position -->
        <CrudFieldSelectorPosition
          api-field-name="Position"
          required
        />
        <!-- Resource -->
        <CrudFieldSelectorResource
          api-field-name="Resource"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
