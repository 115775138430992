<template>
  <crud-table
    :default-visible-columns="['Name', 'People', 'PeopleA', 'PeopleB2B', 'PeopleCoE', 'PeopleB2BA', 'PeopleCoEA', 'Salary',
                               'SalaryA', 'SalaryB2B', 'SalaryCoE', 'SalaryB2BA', 'SalaryCoEA']"
    api="settings/projects"
    api-item="settings/project"
    :versioned="false"
    :columns-settings="{
      Name: { width: 260 },
      People: { width: 150, filterField: false },
      PeopleA: { width: 150, filterField: false, header: 'PeopleA' },
      PeopleB2B: { width: 150, filterField: false, header: 'PeopleB2B' },
      PeopleCoE: { width: 150, filterField: false, header: 'PeopleCoE' },
      PeopleB2BA: { width: 150, filterField: false, header: 'PeopleB2BA' },
      PeopleCoEA: { width: 150, filterField: false, header: 'PeopleCoEA' },
      Salary: { width: 150, filterField: false },
      SalaryA: { width: 150, filterField: false, header: 'SalaryA' },
      SalaryB2B: { width: 150, filterField: false, header: 'SalaryB2B' },
      SalaryCoE: { width: 150, filterField: false, header: 'SalaryCoE' },
      SalaryB2BA: { width: 150, filterField: false, header: 'SalaryB2BA' },
      SalaryCoEA: { width: 150, filterField: false, header: 'SalaryCoEA' }
    }"
  />
</template>
