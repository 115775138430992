<template>
  <crud-table
    :default-visible-columns="['Employee', 'Project', 'Availability', 'Started']"
    :columns-settings="{
      Availability: { width: 150 },
      Started: { width: 170 }
    }"
    api="settings/employee-projects"
    api-item="settings/employee-project"
    :versioned="false"
  />
</template>
