<script>
import { defineAsyncComponent } from 'vue'
import DynamicSelector from '!/components/selectors/DynamicSelector.vue'

export default {
  name: 'HierarchyLineSelector',
  components: {
    DynamicSelector,
    HierarchyLineDetailsPage: defineAsyncComponent(() => import('@/pages/settings/hierarchy-lines/DetailsPage.vue'))
  },
  props: {
    filterMode: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <DynamicSelector
    :label="filterMode ? '' : 'Hierarchy line'"
    :width="720"
    :columns-settings="{
      Employee: {},
      Hierarchy: {},
      Project: {}
    }"
    :width-field-px="filterMode && !fullWidth ? 200 : undefined"
    :full-width="fullWidth"
    api="settings/hierarchy-lines"
    :edit-model-route-name="filterMode ? '' : 'settings-hierarchy-lines-details'"
  >
    <template #editModelContent="{ entityRoute, setEditModelMetaData, closeEditModal, refreshSelector }">
      <HierarchyLineDetailsPage
        :entity-route="entityRoute"
        @change-meta-data="setEditModelMetaData($event)"
        @cancel="closeEditModal"
        @change="refreshSelector"
      />
    </template>
  </DynamicSelector>
</template>
