<script>
import CrudFieldSelectorEmployee from '@/components/forms/crud-fields/CrudFieldSelectorEmployee.vue'
import CrudFieldSelectorResource from '@/components/forms/crud-fields/CrudFieldSelectorResource.vue'

export default {
  components: { CrudFieldSelectorEmployee, CrudFieldSelectorResource }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-resource-admins']"
    api="settings/resource-admin"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Resource -->
        <CrudFieldSelectorResource
          api-field-name="Resource"
          required
        />
        <!-- Admin -->
        <CrudFieldSelectorEmployee
          api-field-name="Admin"
          required
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
