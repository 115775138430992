<script>
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'

export default {
  components: { CrudFieldFile }
}
</script>

<template>
  <crud-details-page
    :back-routes="['settings-employees']"
    api="settings/employee"
    :versioned="false"
  >
    <template #form>
      <fields-col :span="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          label="First and last name"
          required
        />
        <!-- Born -->
        <crud-field-date
          api-field-name="Born"
        />
        <!-- Email -->
        <crud-field-text
          api-field-name="Email"
          required
        />
        <!-- OneOneSupervisor -->
        <crud-field-selector-employee api-field-name="OneOneSupervisor" />
      </fields-col>
      <fields-col :span="16">
        <!-- ProfilePicture -->
        <CrudFieldFile
          api-field-name="ProfilePicture"
          upload-title="Upload picture"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
