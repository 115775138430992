<template>
  <crud-details-page
    :back-routes="['public-relations']"
    api="public-relation"
    :versioned="false"
    disable-top-margin
  >
    <template #form>
      <fields-col :span="8">
        <!-- Media -->
        <crud-field-text
          api-field-name="Media"
          required
          :max="500"
        />
        <!-- Website -->
        <crud-field-text
          api-field-name="Website"
          required
          :max="1000"
        />
        <!-- Contact -->
        <crud-field-textarea
          api-field-name="Contact"
          required
        />
        <!-- ContactPerson -->
        <crud-field-text
          api-field-name="ContactPerson"
          :max="255"
        />
      </fields-col>
      <fields-col :span="16">
        <!-- Info -->
        <crud-field-textarea
          api-field-name="Info"
        />
        <!-- TermsOfCooperation -->
        <crud-field-textarea
          api-field-name="TermsOfCooperation"
        />
        <!-- Comment -->
        <crud-field-textarea
          api-field-name="Comment"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
